.ReferralsTier {
  padding: 20px 30px;
}

.ReferralsTier-form {
  width: 450px;
}

.ReferralsTier-row {
  margin-top: 20px;
}

.ReferralsTier-input {
  padding: 10px;
  border: 1px solid #aaa;
  font-size: 16px;
  width: 100%;
}

.ReferralsTier-label {
  display: block;
  margin-bottom: 5px;
}
